import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
  OverlapRow,
  ThreeColumn,
  ThreeColumnOne,
  ThreeColumnTwo,
  ThreeColumnThree,
  ColumnInternal,
  ColumnInternalTop,
  ColumnInternalBottom,
} from "../components/SectionBlocks"

import {
  Testimonial3ColumnWrapper,
  TestimonialWrapper,
  TestimonialImage,
  TestimonialTitle,
  TestimonialName,
  TestimonialMessage,
} from "../components/Testimonial"

import { HeroHeadline, HeroSubHead } from "../components/HeroSection"

import { FaCheck, FaComments } from "react-icons/fa"

import { StyledIcon } from "../components/StyledIcon"

import SEO from "../components/seo"
import { ButtonPrimary, ButtonSecondary } from "../components/Buttons"

const TrainAtHome = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Train At Home"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Coaching professionals to look great, get stronger & play harder"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.heroImage?.childImageSharp?.fluid}
        bgX="60%"
        bgY="0%"
        // overlay="black"
        // opacity="0.9"
        // style={{ flexGrow: "1", height: "90vh" }}>
      >
        <TwoColumnSection columnLeftWidth="50%" columnRightWidth="50%" sideMargin="5%">
          <TwoColumnSectionLeft>
            <HeroHeadline>
              <h1 style={{ color: theme.colors.white }}>
                <span style={{ color: theme.colors.primaryBlueNormal }}>Achieve Your Goals</span>{" "}
                With Tailored Programs In A Team Environment
              </h1>
            </HeroHeadline>
            <HeroSubHead>
              <p style={{ color: theme.colors.light1 }}>
                Whether you are just starting out or are experienced in the gym, Liftology's coaches
                tailor proven programs to meet your needs so you can reach your goals.
              </p>
              <p style={{ color: theme.colors.light1 }}>
                Lose fat and build muscle as you get stronger and look even better.
              </p>
              <p style={{ color: theme.colors.light1 }}>
                Work out one-on-one with a coach or in a small group training class, you'll always
                get the attention and coaching you need.
              </p>
              <p style={{ color: theme.colors.light1 }}>
                Track your progress and see the results as you achieve your goals.
              </p>
            </HeroSubHead>
            <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "125px" }}>
              <ButtonSecondary to="/free-assessment" style={{ boxShadow: "none" }}>
                Start Today
              </ButtonSecondary>
            </div>
          </TwoColumnSectionLeft>
        </TwoColumnSection>
      </RowSection>
      <RowSection
        rowMargin="5%"
        style={{
          marginTop: "-55px",
        }}>
        <OneColumnSection sideMargin="0%" style={{ width: "100%" }}>
          <OverlapRow
            style={{
              backgroundColor: theme.colors.primaryBlueLighter,
              width: "100%",
              flexGrow: "1",
              borderRadius: "10px",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "15px",
              }}>
              <MetricDivNumber>5,105</MetricDivNumber>
              <MetricDivTitle>Happy Clients</MetricDivTitle>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "15px",
              }}>
              <MetricDivNumber>15</MetricDivNumber>
              <MetricDivTitle>Years Experience</MetricDivTitle>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "15px",
              }}>
              <MetricDivNumber>2</MetricDivNumber>
              <MetricDivTitle>Locations</MetricDivTitle>
            </div>
          </OverlapRow>
        </OneColumnSection>
      </RowSection>

      <RowSection
        rowMargin="5%"
        style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
        <OneColumnSection>
          <HeroHeadline>
            <h1>
              Hi, I'm Mitch Jobson. <br />
              Head coach at{" "}
              <span style={{ color: theme.colors.primaryBlueNormal }}>The Liftology Project</span>
            </h1>
          </HeroHeadline>
        </OneColumnSection>
      </RowSection>
      <RowSection rowMargin="5%">
        <TwoColumnSection columnLeftWidth="50%" columnRightWidth="50%" sideMargin="5%">
          <TwoColumnSectionLeft style={{ alignItems: "center", justifyContent: "flex-end" }}>
            <Img
              fluid={data?.mitchSinglet?.childImageSharp?.fluid}
              alt="Mitch Jobson"
              style={{
                // marginTop: "15px",
                // marginBottom: "15px",
                marginTop: "0px",
                width: "40vw",
                objectPosition: "50% 50%",
              }}
            />
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight style={{ alignItems: "center", justifyContent: "flex-end" }}>
            <HeroSubHead>
              <p>
                You may not believe it, but 16 years ago I used to be just like you. Yes, I am
                serious. Just. Like. You.
              </p>
              <p>
                After being the sporty kid, I left school and didn't look after myself as much as I
                know i should have. I put on a heap of weight
              </p>
              <p>
                Being unhappy, I made a decision to change things. I started eating right and
                working out with a great trainer. I've since lost, and kept off, over 20kg.
              </p>
              <p>
                Since then I've studied, and event taught, tae kwan do and gymnastics, and have been
                a successful personal trainer for over 14 years helping{" "}
                <strong>more than 5,000</strong> clients achieve their goals.
              </p>
              <p>
                I'd love to learn more about what you're looking to achieve and help you get there
                too.
              </p>
              <p>
                Click on the <strong>Start Today</strong> button for a{" "}
                <span style={{ color: theme.colors.primaryOrangeNormal }}>
                  no obligation free assessment
                </span>{" "}
                to see how we can help.
              </p>
            </HeroSubHead>
            <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "125px" }}>
              <ButtonSecondary to="/free-assessment">Start Today</ButtonSecondary>
            </div>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>
      <RowSection rowMargin="15%" style={{ marginTop: "50px" }}>
        <OneColumnSection>
          <HeroHeadline>
            <h1 style={{ textAlign: "center" }}>
              Get Started in{" "}
              <span style={{ color: theme.colors.primaryBlueNormal }}>Three Easy Steps</span> and
              Begin Achieving Your Goals{" "}
              <span style={{ color: theme.colors.primaryOrangeNormal }}>Today</span>
            </h1>
          </HeroHeadline>
        </OneColumnSection>
      </RowSection>
      <RowSection rowMargin="5%" style={{ marginTop: "50px", justifyContent: "center" }}>
        <ThreeColumn>
          <ThreeColumnOne
            style={{
              backgroundColor: theme.colors.primaryLightGray,
              padding: "10px",
              borderRadius: "10px",
            }}>
            <ColumnInternal>
              <ColumnInternalTop>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                <img
                  src={data.step1.publicURL}
                  alt="Step 1"
                  width="100%"
                  height="100%"
                  // width="100%"
                  // height="100%"
                  style={{ padding: "10px" }}
                />
                {/* </div> */}
              </ColumnInternalTop>
              <ColumnInternalBottom>
                <div style={{ textAlign: "center" }}>
                  <HeroHeadline>
                    <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 1</h2>
                  </HeroHeadline>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Book a Free Phone Assessment with one of our senior coaches
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Share your goals and why they are important to you
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Schedule a time for an in person training introduction
                  </div>
                </div>
              </ColumnInternalBottom>
            </ColumnInternal>
          </ThreeColumnOne>
          <ThreeColumnTwo
            style={{
              backgroundColor: theme.colors.primaryLightGray,
              padding: "10px",
              borderRadius: "10px",
            }}>
            <ColumnInternal>
              <ColumnInternalTop>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                <img
                  src={data?.step2.publicURL}
                  alt="Step 2"
                  width="100%"
                  height="100%"
                  // width="300px"
                  // height="300px"
                  style={{ padding: "10px" }}
                />
                {/* </div> */}
              </ColumnInternalTop>
              <ColumnInternalBottom>
                <div style={{ textAlign: "center" }}>
                  <HeroHeadline>
                    <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 2</h2>
                  </HeroHeadline>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>Share your goals with your coach</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Agree on a game plan based on a proven system
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Set yourself up for success with the tools you need to track your progress
                  </div>
                </div>
              </ColumnInternalBottom>
            </ColumnInternal>
          </ThreeColumnTwo>
          <ThreeColumnThree
            style={{
              backgroundColor: theme.colors.primaryLightGray,
              padding: "10px",
              borderRadius: "10px",
            }}>
            <ColumnInternal>
              <ColumnInternalTop>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}> */}
                <img
                  src={data.step3.publicURL}
                  alt="Step 3"
                  width="100%"
                  height="100%"
                  // width="100%"
                  // height="100%"
                  style={{ padding: "10px" }}
                />
                {/* </div> */}
              </ColumnInternalTop>
              <ColumnInternalBottom>
                <div style={{ textAlign: "center" }}>
                  <HeroHeadline>
                    <h2 style={{ color: theme.colors.primaryOrangeNormal }}>Step 3</h2>
                  </HeroHeadline>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>
                    Complete initial measurements and fitness test
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>Start training with your experienced coach</div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: "5px" }}>
                    <FaCheck
                      color={theme.colors.primaryBlueNormal}
                      fontSize={theme.spacings.medium}
                    />
                  </div>
                  <div style={{ padding: "5px" }}>Enjoy the results as you achieve your goals.</div>
                </div>
              </ColumnInternalBottom>
            </ColumnInternal>
          </ThreeColumnThree>
        </ThreeColumn>
      </RowSection>
      <RowSection>
        <OneColumnSection style={{ width: "100%", marginBottom: "25px" }}>
          <ButtonSecondary to="/free-assessment">Get Started Now</ButtonSecondary>
        </OneColumnSection>
      </RowSection>
      <div id="_testimonials">&nbsp;</div>
      <div
        style={{
          backgroundColor: theme.colors.white,
          paddingBottom: "40px",
          paddingTop: "40px",
          marginBottom: "10px",
        }}>
        <center>
          <StyledIcon size="120px" color={theme.colors.primaryBlueNormal}>
            <FaComments />
          </StyledIcon>

          <h1
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              lineHeight: theme.spacings.xLarge,
            }}>
            What <span style={{ color: theme.colors.primaryBlueNormal }}>real people say</span>{" "}
            about Liftology
          </h1>
          <p>Don't take our word for it, hear directly from some of our clients.</p>
        </center>
        <Testimonial3ColumnWrapper>
          <TestimonialWrapper>
            <TestimonialImage
              width="120px"
              height="120px"
              alt="Andrew Hatfield"
              src={data?.headshotAndrewHatfield?.childImageSharp?.gatsbyImageData}
            />
            {/* <Img fixed={data?.headshotAndrewHatfield?.childImageSharp.fixed} alt="Andrew" /> */}
            <TestimonialName>Andrew Hatfield</TestimonialName>
            <TestimonialTitle>Marketing</TestimonialTitle>
            <TestimonialMessage>
              After many years of running, and playing tennis and soccer, I ended up injuring my
              back. Sneezing was often dangerous enough to put me out of action for days. Working
              with Mitch for just over a year, I now no longer fear sneezing and can easily keep up
              with my kids, move furniture, and lift more than my bodyweight!
            </TestimonialMessage>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <TestimonialImage
              width="120px"
              height="120px"
              alt="Byron Paine"
              src={data?.headshotByronPaine?.childImageSharp?.gatsbyImageData}
            />

            <TestimonialName>Byron Paine</TestimonialName>
            <TestimonialTitle>Retail</TestimonialTitle>
            <TestimonialMessage>
              Mitch has helped me realise progress comes in all shapes and forms, and that it’s not
              going to be an easy task hut that it can be done. Mitch has shown incredible
              dedication to myself and his other clients which drives our thirst for strength even
              more.
            </TestimonialMessage>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <TestimonialImage
              width="120px"
              height="120px"
              alt="Craig MacDonald"
              src={data?.headshotCraigMacDonald?.childImageSharp?.gatsbyImageData}
            />

            <TestimonialName>Craig MacDonald</TestimonialName>
            <TestimonialTitle>Property</TestimonialTitle>
            <TestimonialMessage>
              Mitch isn't your bog standard trainer, and I can say that having trained with a couple
              before him. Mitch is your coach. Not only does he know his craft well, he is always
              learning and staying up to date. Attentive and focused, picking up on small but
              important incremental improvements.
            </TestimonialMessage>
          </TestimonialWrapper>
        </Testimonial3ColumnWrapper>
        <ButtonPrimary
          to="/free-assessment"
          style={{ textAlign: "center", marginLeft: "20px", marginRight: "20px" }}>
          {/* <p stye={{ fontSize: theme.spacings.xSmall }}> Speak With A Coach Today</p> */}
          Start Today And Get Similar Results
        </ButtonPrimary>
      </div>
    </PageLayout>
  )
}

export default TrainAtHome

const MetricDivNumber = (props) => {
  return (
    <div
      style={{
        color: theme.colors.light1,
        fontFamily: theme.fonts.header2,
        fontSize: theme.spacings.medium,
      }}>
      {props.children}
    </div>
  )
}

const MetricDivTitle = (props) => {
  return (
    <div
      style={{
        color: theme.colors.light1,
        fontFamily: theme.fonts.header2,
        fontSize: theme.spacings.small,
      }}>
      {props.children}
    </div>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    heroImage: file(absolutePath: { regex: "/gym-group-training-darkened-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    mitchSinglet: file(absolutePath: { regex: "/Mitch-Jobson-White-Singlet-497x885.png/" }) {
      childImageSharp {
        fixed(width: 497, height: 885, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 500, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    step1: file(absolutePath: { regex: "/pick-date.svg/" }) {
      publicURL
    }

    step2: file(absolutePath: { regex: "/conversation.svg/" }) {
      publicURL
    }

    step3: file(absolutePath: { regex: "/train-at-home.svg/" }) {
      publicURL
    }

    headshotAndrewHatfield: file(absolutePath: { regex: "/andrewhatfield-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotByronPaine: file(absolutePath: { regex: "/byron-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotCraigMacDonald: file(absolutePath: { regex: "/craig-macdonald-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }
  }
`
